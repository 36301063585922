import React, { useEffect, useState } from 'react';
import './Layout.css'
import { useAuth } from "../../providers/authProvider";
import logoImage from "../../assets/img/GMMI_Logo_160.png"
import Settings from '../../services/settings';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import AppLayout from './AppLayout';
import { useHistory } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";
import Footer from '../Footer/Footer';
import { BrowserUtils } from "@azure/msal-browser";
import { Dropdown } from 'react-bootstrap';

const Layout = (props) => {
  const { removeToken } = useAuth();
  const isAuthenticated = useIsAuthenticated();


  const { instance, inProgress } = useMsal();

  const history = useHistory();
  const [currentUser, setCurrentUser] = useState('');

  const [selectedClient, setSelectedClient] = useState('');
  const [displaySelectedClient, setDisplaySelectedClient] = useState(false);
  const [clientLabel, setClientLabel] = useState('');
  const [clientConfig, setClientConfig] = useState('');
  const [isGMMIUser, setIsGMMIUser] = useState(false);
  const [displayContent, setDisplayContent] = useState(false);

  useEffect(() => {
    let script = document.createElement("script");

    script.src = "/app.gmmi.js";
    script.async = false;
    document.body.appendChild(script);


  }, []);

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      setCurrentUser(instance.getAllAccounts()[0]?.name);
      instance.setActiveAccount(instance.getAllAccounts()[0]);
    }

  }, [inProgress, instance]);

  useEffect(() => {
    if (localStorage && isAuthenticated) {
      const currentClient = localStorage.getItem(Settings.getCurrentClientKey());
      setSelectedClient(JSON.parse(currentClient));
      const isGMMI = localStorage.getItem(Settings.getIsGMMIKey()) ?? false;
      setIsGMMIUser(isGMMI);

      if (currentClient) {
        setDisplaySelectedClient(true);
        setDisplayContent(true);
      }
      else {
        setDisplaySelectedClient(false);
      }
      //get client configuration from local storage
      const clientProfile = localStorage.getItem(Settings.getClientConfigurationKey());
      if (clientProfile) {
        setClientConfig(JSON.parse(clientProfile));

      }
      //}
      if (isGMMI) {
        setClientLabel("Viewing Client");
        setIsGMMIUser(true);
      }
      else {
        setClientLabel("ClientName");
        setIsGMMIUser(false);
      }
    }
    else {
      setDisplaySelectedClient(false);
      setDisplayContent(false);
    }
  }, [isAuthenticated, props.selectedClient]);

  const handleLogout = () => {
    removeToken();
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
      onRedirectNavigate: () => !BrowserUtils.isInIframe()
    })
  }

  const onClientSelected = () => {
    let path = `/client-selector`;
    history.push(path);
  }

  return (
    <div >
      <UnauthenticatedTemplate>
        <AppLayout></AppLayout>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        {displayContent ?
          <header className="u-header u-header--sticky-top u-header--toggle-section u-header--change-appearance">
            <div className="u-header__section u-header__section--hidden u-header__section--dark g-bg-gmmi-blue g-py-10">
              <div className="container">
                <div className="row flex-column flex-md-row justify-content-between align-items-center text-uppercase g-font-weight-600 g-color-white">
                  <div className="col-auto g-px-15">

                  </div>
                  <div className="col-auto g-pos-rel g-px-15">
                    <ul className="list-inline g-overflow-hidden g-font-size-12 g-mt-minus-10 g-mx-minus-4 mb-0">
                      <li className="list-inline-item g-mx-4 g-mt-10">
                        You are logged in as: {currentUser}
                      </li>
                      <li className="list-inline-item g-mx-4 g-mt-10">
                        |
                      </li>
                      {
                        displaySelectedClient ?
                          <li className="list-inline-item g-mx-4 g-mt-10">
                            {clientLabel}: {selectedClient.label}
                          </li> : null
                      }
                      {
                        displaySelectedClient ?
                          <li className="list-inline-item g-mx-4 g-mt-10">
                            |
                          </li> : null
                      }
                      {isGMMIUser ?
                        <li className="list-inline-item g-mx-4 g-mt-10">
                          <a href="/client-selector" className='g-color-white g-color-gmmi-red--hover g-text-underline--none--hover' onClick={(e) => e.preventDefault() & onClientSelected()}>Select client</a>
                        </li> : null}
                      <li className="list-inline-item g-mx-4 g-mt-10">
                        <a onClick={(event) => handleLogout("redirect")} className="g-color-white g-color-gmmi-red--hover g-text-underline--none--hover" style={{ cursor: 'pointer' }} id='cca-reporting-logout'>Logout</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="u-header__section u-header__section--light g-bg-white-opacity-0_8 g-py-10"
              data-header-fix-moment-exclude="g-bg-white-opacity-0_8 g-py-10" data-header-fix-moment-classes="g-bg-white u-shadow-v18 g-py-0">
              <nav className="js-mega-menu navbar navbar-expand-lg">
                <div className="container">
                  <button className="navbar-toggler navbar-toggler-right btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-top-3 g-right-0" type="button" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navBar" data-toggle="collapse" data-target="#navBar">
                    <span className="js-hamburger hamburger hamburger--collapse">
                      <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                      </span>
                    </span>
                  </button>

                  <div style={{ display: 'flex' }}>
                    <a href="https://www.gmmi.com/" className="navbar-brand"><img src={logoImage} alt="GMMI Cost Containment" /></a>
                  </div>
                  <div className="collapse navbar-collapse align-items-center flex-sm-row g-pt-10 g-pt-5--lg" id="navBar">
                    <ul className="navbar-nav ml-auto text-uppercase g-font-weight-600 u-main-nav-v2 u-sub-menu-v2">
                      {clientConfig.value?.currentActivity
                        ?
                        <li className="nav-item g-mx-2--md g-mx-5--xl g-mb-5 g-mb-0--lg" id="navItem-b8d0a5f45e734ae8b5a464252230feec">
                          <a href="/current-activity" className="nav-link">Current Activity</a>
                        </li>
                        :
                        null
                      }
                      {clientConfig.value?.patientSearch
                        ?
                        <li className="nav-item g-mx-2--md g-mx-5--xl g-mb-5 g-mb-0--lg" id="navItem-672ea1b687cd46fc9855827840071551">
                          <a href="/patient-search" className="nav-link">Patient Search</a>
                        </li>
                        : null
                      }
                       
                      {clientConfig.value?.providerSearches && clientConfig.value?.providerSearches.length == 1
                        ?
                        <li className="nav-item g-mx-2--md g-mx-5--xl g-mb-5 g-mb-0--lg" id="navItem-daa2537e9d7441439f10463555572bb3">
                          <a href={clientConfig.value?.providerSearches[0].url} target="_blank" className="nav-link">PROVIDER SEARCH</a>
                        </li>
                        : null
                      }
                      {clientConfig.value?.providerSearches && clientConfig.value?.providerSearches.length > 1
                        ?

                        <li className="nav-item hs-has-sub-menu g-mx-2--md g-mx-5--xl g-mb-5 g-mb-0--lg" id="navItem-daa2537e9d7441439f10463555572bb3">
                          <a href="#" className="nav-link" id="nav-link-1" aria-haspopup="true" aria-expanded="false" aria-controls="nav-submenu-1">
                            PROVIDER SEARCH
                          </a>
                          <ul className="hs-sub-menu list-unstyled g-mt-17--lg g-mt-7--lg--scrolling" id="nav-submenu-1" aria-labelledby="nav-link-1">
                            {clientConfig.value?.providerSearches.map((item) => (
                              <li key={item.code}>
                                <a href={item.url} target="_blank">{item.name}</a>
                              </li>
                            ))}
                          </ul>
                        </li>


                        : null
                      }
                      {clientConfig.value?.reports && clientConfig.value?.reports.length > 1
                        ?
                        <li className="nav-item g-mx-2--md g-mx-5--xl g-mb-5 g-mb-0--lg" id="navItem-3d9566f2812a494c93e9ddfc96bf5502">
                          <a href="/reports" className="nav-link">REPORTS</a>
                        </li>
                        :
                        null
                      }
                       {clientConfig.value?.clientAuth &&
                          <li className="nav-item g-mx-2--md g-mx-5--xl g-mb-5 g-mb-0--lg" id="navItem-672ea1b687cd46fc98558271234071551">
                              <a href="/authorization" className="nav-link">Authorization</a>
                          </li>
                      }
                      {clientConfig.value ?
                        <li className="nav-item g-mx-2--md g-mx-5--xl g-mb-5 g-mb-0--lg" id="navItem-38978c51d50344d1b2f5a7accf7bb950">
                          <a href="/account-management" className="nav-link">CONTACT</a>
                        </li> :
                        null}
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </header >

          : null}

        <div className="body-marginTop">
          {displayContent ? props.children : null}
        </div>
        {
          displayContent ?
            <Footer></Footer> : null
        }
        <div className="cookie-banner" style={{ display: 'none' }}>
          <p> This website uses its own technical and third party cookies in order to improve your browsing experience and provide you a service in line with your preferences.
            If you close this banner or access any element underlying this banner, you'll provide consent regarding cookies. If you want to learn more, read our <a href="https://us.generaliglobalassistance.com/cookies-policy/" target="_new">cookie policy</a>
            <br />
            <button type="button" className="btn btn-primary cookie-banner-close">Agree</button>
          </p>
        </div>
        <a className="js-go-to u-go-to-v1" href="#!" data-type="fixed" data-position='{"bottom": 15, "right": 15}' data-offset-top="400" data-compensation="#js-header" data-show-effect="zoomIn" aria-label="scroll-to-top">
          <i className="hs-icon hs-icon-arrow-top"></i>
        </a>
      </AuthenticatedTemplate >

    </div >
  );
}

export default Layout;
