import AuthService from './authService';
import ErrorDisplay from './errorDisplay';
import ClientConfigurationService from './clientConfigurationService'
import axios from 'axios';

const ItnAuthService = {
    getAuthorizationData: (data, cancelToken, callback) => {
        AuthService.getAuthToken(false, (token) => {
            axios.post('/api/itn/bills', data, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(res => {
                callback(res.data.bills);
            }).catch((error => {
                if (axios.isCancel(error)) {
                    console.log("Request canceled.")
                } else {
                    ErrorDisplay.renderError('An error occurred retrieving authorizations');
                    callback([]);
                }
            }));
        });
    },

    getDenyList: (callback) => {
        AuthService.getAuthToken(false, (token) => {
            axios.get('/api/itn/denylist', {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(res => {
                callback(res.data.lookupItems);
            }).catch((error => {
                ErrorDisplay.renderError('An error occurred retrieving deny list');
                callback([]);
            }));
        });
    },

    getInfoList: (callback) => {
        AuthService.getAuthToken(false, (token) => {
            axios.get('/api/itn/infolist', {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(res => {
                callback(res.data.lookupItems);
            }).catch((error => {
                ErrorDisplay.renderError('An error occurred retrieving info list');
                callback([]);
            }));
        });
    },

    processAuthorization: (data, cancelToken, callback) => {
        AuthService.getAuthToken(false, (token) => {
            axios.post('/api/itn/process', data, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(res => {
                callback(res.data);
            }).catch((error => {
                if (axios.isCancel(error)) {
                    console.log("Request canceled.")
                } else {
                    ErrorDisplay.renderError('An error occurred saving authorization');
                    callback([]);
                }
            }));
        });
    }
}

export default ItnAuthService;