import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import React, { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import { Form, Row, Col, Button, FormLabel, Modal } from 'react-bootstrap';
import Dialog from 'react-bootstrap-dialog'
import axios from 'axios';
import moment from 'moment';
import MD5 from 'object-hash';

import Picklist from './Picklist'
import PatientSearchService from '../../services/patientSearchService';
import AnalyticsService from '../../services/analyticsService';

const CurrentActivityForm = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [allClients, setAllClients] = useState(true);
    const [clientList, setClientList] = useState();
    const [clientCodeList, setClientCodeList] = useState([]);
    const [selectedClientList, setSelectedClientList] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [searchByAllActivities, setSearchByAllActivities] = useState(false);
    const [searchByMedicalRecords, setSearchByMedicalRecords] = useState(false);
    const [searchByCaseDocs, setSearchByCaseDocs] = useState(false);
    const [searchByBills, setSearchByBills] = useState(false);
    const [searchByNotes, setSearchByNotes] = useState(false);
    const [showActivityError, setShowActivityError] = useState(false);
    const [showClientError, setShowClientError] = useState(false);
    const [dateValidationMessage, setDateValidationMessage] = useState('');
    const [cancelToken, setCancelToken] = useState();
    const [currentSearchHash, setCurrentSearchHash] = useState('');
    const [showClientDropdown, setShowClientDropdown] = useState(false);
    const [showClientPicker, setShowClientPicker] = useState(false);
    const [clientsForDropdown, setClientsForDropdown] = useState([]);

    let dialogRef = useRef();

    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    const invalidRangeText = 'Please ensure start date is before end date.';
    const sixMonthText = 'Please ensure start date and end date are within 6 months.';

    useEffect(() => {
        PatientSearchService.getAvailableClients((data) => {
            setClientList(data);
            setClientCodeList(Object.keys(data));

            var keys = Object.keys(data);
            var dropdownClients = [];
            
            for (var i = 0; i < keys.length; i++) {
                var item = {};
                item.key = keys[i];
                item.value = data[item.key];
                dropdownClients.push(item);
            }

            setClientsForDropdown(dropdownClients);

            if (keys.length === 1) {
                setShowClientDropdown(true);
                setShowClientPicker(false);
                setAllClients(true);
            } else {
                setShowClientDropdown(false);
                setShowClientPicker(true);
            }
        });

        if (props.searchParams) {
            setStartDate(props.searchParams.startDate);
            setEndDate(props.searchParams.endDate);
            setSearchByAllActivities(props.searchParams.searchByAllActivities);
            setSearchByBills(props.searchParams.searchByBills);
            setSearchByCaseDocs(props.searchParams.searchByCaseDocs);
            setSearchByMedicalRecords(props.searchParams.searchByMedicalRecords);
            setSearchByNotes(props.searchParams.searchByNotes);
            setAllClients(props.searchParams.allClients);

            if (!props.searchParams.allClients) {
                setSelectedClientList(props.searchParams.selectedClientList);
            }
        } else {
            setSearchByAllActivities(true);
        }

        if (isIE11) {
            intitializeDatepicker();
        }        
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            if (isStartDateGreater()) {
                setDateValidationMessage(invalidRangeText);
            } else if (isGreaterThanSixMonths()) {
                setDateValidationMessage(sixMonthText);
            } else {
                setDateValidationMessage('');
            }
        } else {
            setDateValidationMessage('');
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (searchByBills || searchByCaseDocs || searchByMedicalRecords || searchByNotes) {
            setSearchByAllActivities(false);
        }
    }, [searchByBills, searchByCaseDocs, searchByMedicalRecords, searchByNotes]);

    useEffect(() => {
        if (searchByAllActivities) {
            setSearchByBills(false);
            setSearchByCaseDocs(false);
            setSearchByMedicalRecords(false);
            setSearchByNotes(false);
        }
    }, [searchByAllActivities]);

    const intitializeDatepicker = () => {
        $("input[type='date']#startDate").datepicker({
            onSelect: function (dateText) {
                $(this).each(function () { this.setCustomValidity('') });
                setStartDate(dateText);
            }
        });
        $("input[type='date']#endDate").datepicker({
            onSelect: function (dateText) {
                $(this).each(function () { this.setCustomValidity('') });
                setEndDate(dateText);
            }
        });
    }

    const isStartDateGreater = () => {
        if (startDate && startDate !== "" && endDate && endDate !== "") {
            return moment(endDate).isBefore(moment(startDate));
        }
    }

    const isGreaterThanSixMonths = () => {
        if (startDate && startDate !== "" && endDate && endDate !== "") {
            return moment(endDate).diff(moment(startDate), 'months') > 5;
        }
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        setIsLoading(true);
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setIsLoading(false);
            return;
        }

        if (isStartDateGreater()) {
            setDateValidationMessage(invalidRangeText);
            setIsLoading(false);
            return;
        }

        if (isGreaterThanSixMonths()) {
            setDateValidationMessage(sixMonthText);
            setIsLoading(false);
            return;
        }

        if (!allClients && selectedClientList.length <= 0) {
            setShowClientError(true);
            setIsLoading(false);
            return;
        } else {
            setShowClientError(false);
        }

        var activityList = new Array();

        if (searchByAllActivities) {
            activityList.push("ALL");
        } else {
            if (searchByMedicalRecords) {
                activityList.push("MR");
            } else if (searchByCaseDocs) {
                activityList.push("CASE");
            } else if (searchByBills) {
                activityList.push("BILLS");
            } else if (searchByNotes) {
                activityList.push("NOTES");
            }
        }

        if (activityList.length <= 0) {
            setShowActivityError(true);
            setIsLoading(false);
            return;
        } else {
            setShowActivityError(false);
        }

        var postData = {
            startDate: startDate,
            endDate: endDate,
            clients: allClients ? clientCodeList : selectedClientList,
            activityList: activityList
        };

        var hashVal = MD5(postData);
        
        if (hashVal === currentSearchHash) {
            return;
        } else if (currentSearchHash !== '' && currentSearchHash !== hashVal) {
            showNewSearchModal(postData);
            return;
        }

        getCurrentActivity(postData);
    }

    const resetForm = (e) => {
        e.preventDefault();

        if (cancelToken) {
            cancelToken.cancel("Current activity search reset");
        }

        setCurrentSearchHash('');
        setIsLoading(false);
        setAllClients(true);
        setSearchByAllActivities(true);
        setStartDate('');
        setEndDate('');
        props.onPatientDetailsChange([]);
        props.searchActive(false);

        if (isIE11) {
            intitializeDatepicker();
        }
    }

    const getCurrentActivity = (request) => {
        if (cancelToken) {
            cancelToken.cancel("Current activity search reset");
        }
        
        setIsLoading(true);

        var searchParams = {
            startDate,
            endDate,
            allClients,
            searchByAllActivities,
            searchByBills,
            searchByCaseDocs,
            searchByMedicalRecords,
            searchByNotes,
            selectedClientList
        }

        props.onSearchParamsChange(searchParams);

        setCurrentSearchHash(MD5(request));
        var instanceToken = axios.CancelToken.source();
        setCancelToken(instanceToken);

        PatientSearchService.getCurrentActivity(request, instanceToken, function (data) {
            var msg = "Current Activity Search";
            props.onPatientDetailsChange(data);
            props.searchActive(true);
            props.searchMsg(msg);
            setIsLoading(false);
            setCurrentSearchHash('');
            AnalyticsService.sendEventWithClient("cc_current_activity");
        });
    }

    const onSelectedClientsChange = (clients) => {
        setSelectedClientList(clients);
    }

    const onStartDateChange = (e) => {
        setStartDate(e.target.value);
        e.target.setCustomValidity('');
    }

    const onEndDateChange = (e) => {
        setEndDate(e.target.value);
        e.target.setCustomValidity('');
    }

    const showNewSearchModal = (data) => {
        const customBody = (
            <p>
                Your previous search is still running. Would you like to proceed with these new search parameters instead?
            </p>
        )

        dialogRef.show({
            body: customBody,
            bsSize: 'medium',
            actions: [
                Dialog.Action(
                    'No',
                    () => { },
                    'btn btn-outline-dark'
                ),
                Dialog.Action(
                    'Yes',
                    () => getCurrentActivity(data),
                    'btn btn-outline-dark'
                )
            ],
        });
    }

    return (
        <>
            <ReactNotification />
            <Dialog ref={(component) => { dialogRef = component }} />
            <div className="g-ml-25">
                <h1>Current Activity</h1>
            </div>
            <div className="lead g-mb-40 g-ml-25">
                <p>Use the form below to search for current activity.</p>
            </div>
            <div className="row justify-content-center patientSearchForm">
                <div className="col-md-6">
                    <Form onSubmit={onFormSubmit}>
                        {showClientDropdown &&
                            <Form.Group as={Row} controlId="clientDropdown">
                                <Form.Label column md="4" className="required">Client:</Form.Label>
                                <Col md="6">
                                    <Form.Control as="select">
                                        {
                                            clientsForDropdown.map((client, index) => {
                                                return (
                                                    <option key={index} value={client.key}>{client.value}</option>
                                                );
                                            })
                                        }
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                        }
                        {showClientPicker &&
                            <Form.Group as={Row} controlId="allClients">
                                <Form.Label column md="4" className="required">Clients:</Form.Label>
                                <Col md="6">
                                    <Form.Check inline label="All Clients"
                                        checked={allClients} onChange={e => setAllClients(!allClients)} />
                                </Col>
                            </Form.Group>
                        }
                        {(!showClientPicker && !showClientDropdown) &&
                            <Form.Group as={Row} controlId="noClients">
                                <Form.Label column md="4" className="required">Clients:</Form.Label>
                                <Col md="6">
                                    <b>** Clients Loading **</b>
                                </Col>
                            </Form.Group>
                        }
                        {!allClients && showClientPicker &&
                            <Form.Group as={Row} controlId="selectedClients">
                                <Picklist data={clientList} onChange={onSelectedClientsChange} initialSelected={props.searchParams ? props.searchParams.selectedClientList : []} leftLabel="Available Clients:" rightLabel="Selected Clients:" />
                                {showClientError &&
                                    <Col md="12">
                                        <span className="float-right mr-2">Please select at least one client.</span>
                                    </Col>
                                }
                            </Form.Group>
                        }
                        <Form.Group as={Row}>
                            <Form.Label column md="4" className="required">Activity:</Form.Label>
                            <Col md="6">
                                <Form.Check checked={searchByAllActivities} inline label="All" onChange={e => setSearchByAllActivities(!searchByAllActivities)} />
                                <Form.Check checked={searchByMedicalRecords} inline label="Medical Records" value="MR" onChange={e => setSearchByMedicalRecords(!searchByMedicalRecords)} />
                                <Form.Check checked={searchByCaseDocs} inline label="Case Docs" value="CASE" onChange={e => setSearchByCaseDocs(!searchByCaseDocs)} />
                                <Form.Check checked={searchByBills} inline label="Bills" value="BILLS" onChange={e => setSearchByBills(!searchByBills)} />
                                <Form.Check checked={searchByNotes} inline label="Notes" value="NOTES" onChange={e => setSearchByNotes(!searchByNotes)} />
                                {showActivityError &&
                                    <div>
                                        <span className="ml-3">Please select at least one activity type.</span>
                                    </div>
                                }
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="startDate">
                            <Form.Label column md="4" className="required">Start Date:</Form.Label>
                            <Col md="6">
                                <Form.Control value={startDate} as="input" type="date" required={true} onChange={(e) => onStartDateChange(e)} onInvalid={(e) => e.target.setCustomValidity('Please enter a date to search by')} onInput={(e) => e.target.setCustomValidity('')}></Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="endDate">
                            <Form.Label column md="4" className="required">End Date:</Form.Label>
                            <Col md="6">
                                <Form.Control value={endDate} as="input" type="date" required={true} onChange={(e) => onEndDateChange(e)} onInvalid={(e) => e.target.setCustomValidity('Please enter a date to search by')} onInput={(e) => e.target.setCustomValidity('')}></Form.Control>
                            </Col>
                        </Form.Group>
                        {dateValidationMessage.length > 0 &&
                            <Row>
                                <Col md="4"></Col>
                                <Col md="6">
                                    <span>{dateValidationMessage}</span>
                                </Col>
                            </Row>
                        }
                        <Button variant="primary" type="submit" className="btn btn-md u-btn-primary">Submit</Button>
                        <Button variant="primary" type="reset" className="gmmi-red" onClick={resetForm}>Reset</Button>
                    </Form>
                </div>
                {isLoading &&
                    <Col md="12" className="justify-content-center providersLoading">
                        <div className="spinner-border"></div>
                    </Col>
                }
            </div>
        </>
    )
};

export default CurrentActivityForm;
